/* eslint-disable no-fallthrough */
/* eslint-disable no-prototype-builtins */

import axios from 'axios'
import { Service } from 'axios-middleware'
import { Wrapper as appWrapperInterface } from '@researchmetrics/app-wrapper'
import rmOauth from './rm.oauth.js'
import errors from './network-manager-errors.js'
// might have to use the old app wrapper to test

const service = new Service(axios)
/*
const authenticationFailureErrors = {
  inboxCreate: ''
}
*/

let rmOauthToken = null
const onGetRmOauthTokenSuccess = function(token) {
  rmOauthToken = token
}
const onGetRmOauthTokenError = function(errorObj) {
  if (console && console.log) {
    console.log(
      'network-manager-web-implementation -> onGetRmOauthTokenError',
      errorObj
    )
  }
}

const doesUrlRequireAuthorization = function(url) {
  //for the moment only /api/v1/ reuires the Authorization header
  if (typeof url === 'string') {
    return url.indexOf('/api/v1/') > -1 || url.indexOf('/api/v2/') > -1
  }
  return false
}

service.register({
  onRequest(config) {
    if (config.hasOwnProperty('headers') === false) {
      config.headers = {}
    }

    if (
      doesUrlRequireAuthorization(config.url) &&
      config.headers.hasOwnProperty('Authorization') === false
    ) {
      if (rmOauthToken) {
        //reuse token
        config.headers['Authorization'] = 'Bearer ' + rmOauthToken
        return config
      } else {
        //create token
        return rmOauth
          .getToken(onGetRmOauthTokenSuccess, onGetRmOauthTokenError)
          .then(user => {
            rmOauthToken = user.access_token
            config.headers['Authorization'] = 'Bearer ' + rmOauthToken
            return config
          })
          .catch(function(e) {
            console.log('Failed to get token error: ' + e)
            return config
          })
      }
    } else {
      return config
    }
  },
  onSync(promise) {
    return promise
  },
  onResponse(response) {
    let isUnauthorized = false
    let responseData = response.data
    const errorArray = ['<Error>', 'Authentication Failure', '</Error>']

    if (typeof responseData === 'string') {
      // first try to parse response data to json
      try {
        responseData = JSON.parse(responseData)
      } catch (error) {
        // empty catch statement ...
      }
    }

    if (typeof responseData === 'string') {
      switch (true) {
        // removed handling for empty string as it interferes greatly with IMS
        // case responseData === authenticationFailureErrors.inboxCreate:
        // the array.join approach is used so the network manager code itself does not contain the error string we are checking for
        case responseData.indexOf(errorArray.join('')) > -1:
          isUnauthorized = true
          break
        default:
      }
    } else {
      switch (true) {
        case responseData.status === 401:
        // empty object handle (default OPEN response for unauthenticated users)
        case Object.keys(responseData).length === 0:
          if (response.request.responseURL.indexOf('/open/data.asp') === -1) {
            // unless the request is to open/data.asp do not consider {},[] or "" as an unauthorized
            break
          }
        case responseData &&
          responseData.response &&
          responseData.response.error === 'Authentication Failure':
          isUnauthorized = true
          break
        default:
      }
    }
    //  && response.config.method.get == 'get'
    // vdimov - I removed the above code snippet from the if
    // it is wrong to limit the redirect to get requests only. Aside from that, there is no property method.get.
    // The correct check should have been response.config.method === "get"
    if (isUnauthorized) {
      const param = {
        src: `/auth/index.asp`,
        location: appWrapperInterface.loadURLLocations.self
      }

      appWrapperInterface.loadURL(param)

      return response
    } else {
      return response
    }
  },
  onResponseError(error) {
    let isUnauthorized = false
    console.log('error', error)
    if (error == 'ErrorResponse: login_required') {
      isUnauthorized = true
    } else if (error && error.request) {
      const req = error.request
      switch (req.status) {
        case 401:
          isUnauthorized = true
          break
        default:
          break
      }
    }

    if (isUnauthorized) {
      const param = {
        src: `/auth/index.asp`,
        location: appWrapperInterface.loadURLLocations.self
      }

      appWrapperInterface.loadURL(param)
    }
    //method must always throw an error or return a promise
    //https://emileber.github.io/axios-middleware/#/api/methods
    throw error
  }
})

const httpRequestErrorHandler = function(axiosError) {
  const error = {}
  if (axiosError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    error.name = errors.ERROR_SERVER_ERROR
    error.message = axiosError.message
    error.responseData = axiosError.response.data
    error.statusCode = axiosError.response.status
    error.statusText = axiosError.response.statusText
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    error.name = errors.ERROR_SERVER_UNREACHABLE
    error.message = 'Request failed. Server cannot be reached.'
  } else {
    error.name = errors.ERROR_UNEXPECTED
    error.message = axiosError.message
  }
  return error
}

const validateHTTPParameters = function(settings, reject) {
  // validating URL parameter
  if (typeof settings.url !== 'string' || settings.url.length === 0) {
    reject({
      name: errors.ERROR_INVALID_PARAMETERS,
      message: 'Invalid URL parameter.'
    })
  }

  if (settings.config) {
    if (typeof settings.config !== 'object') {
      reject({
        name: errors.ERROR_INVALID_PARAMETERS,
        message: 'Invalid Config parameter. Must be object.'
      })
    }

    if (settings.config.headers) {
      if (typeof settings.config.headers !== 'object') {
        reject({
          name: errors.ERROR_INVALID_PARAMETERS,
          message:
            'Invalid Config parameter. headers property in config must be an object with key value pairs'
        })
      }
    }
  }

  // validating HTTP verb
  switch (settings.method) {
    case 'get':
    case 'post':
    case 'put':
    case 'patch':
      break
    default:
      reject({
        name: errors.ERROR_INVALID_PARAMETERS,
        message: 'Invalid HTTP Verb parameter.'
      })
  }
}
const networkManagerWebImplementation = {
  getToken(resolve, reject) {
    if (rmOauthToken === null) {
      return new Promise(function(resolve) {
        rmOauth
          .getToken(onGetRmOauthTokenSuccess, onGetRmOauthTokenError)
          .then(
            user => {
              resolve(user.access_token)
            },
            error => {
              reject(error)
            }
          )
          .catch(e => {
            console.error('ERROR', e)
          })
      })
    } else {
      resolve(rmOauthToken)
    }
  },
  doHttpRequest(resolve, reject, settings) {
    validateHTTPParameters(settings, reject)

    switch (settings.method) {
      case 'get':
        axios
          .get(settings.url, settings.config)
          .then(resolve)
          .catch(function(error) {
            reject(httpRequestErrorHandler(error))
          })
        break
      case 'post':
        axios
          .post(settings.url, settings.postBody, settings.config)
          .then(resolve)
          .catch(function(error) {
            reject(httpRequestErrorHandler(error))
          })
        break
      case 'patch':
        axios
          .patch(settings.url, settings.postBody, settings.config)
          .then(resolve)
          .catch(function(error) {
            reject(httpRequestErrorHandler(error))
          })
        break
      case 'put':
        if (typeof settings.config === 'undefined') {
          settings.config = {}
        }
        if (settings.config.headers) {
          settings.config.headers['Content-Type'] = 'application/json'
        } else {
          settings.config.headers = {
            'Content-Type': 'application/json'
          }
        }
        axios
          .put(settings.url, settings.postBody, settings.config)
          .then(resolve)
          .catch(function(error) {
            reject(httpRequestErrorHandler(error))
          })
        break
      default:
        break
    }
  }
}

export default networkManagerWebImplementation
