/* eslint-disable no-unused-vars */
import networkManagerBridgeAPI from './network-manager-bridge-api.js'

const fileManagerAPI = {
  /**
   * Store already downloaded index files and schema files
   */
  cache: {},

  config: {},

  /**
   * 
   * @param {Object} objConfig 
   * {
   *  "Velocitant" : {
        "version" : "v12345",
        "path" : "https://rmvelocityfrontend.blob.core.windows.net/filestore",
        "manifest" : {
          "CodeSnippets" : {
            "indexVersion" : "v12",
            "schemaVersion" : "v12345"
          },
          ...
        }
      }
    }
  */
  setConfig(objConfig) {
    for (var namespace in objConfig) {
      fileManagerAPI.config[namespace] = objConfig[namespace]
      fileManagerAPI.cache[namespace] = {}
    }
  },

  getAbsolutePath(namespaceObjectName, url) {
    let basePath

    if (!/^https?:\/\//i.test(url)) {
      if (typeof fileManagerAPI.config[namespaceObjectName] !== 'undefined') {
        basePath = fileManagerAPI.config[namespaceObjectName].path
        url = `${basePath}${url}`
      }
    }

    return url
  },

  /**
   * Check if query base entity is in the release manifest
   * @param {String} entityObjectName
   * @returns
   */
  isEntityProcessableFromCDN(namespaceObjectName, entityObjectName) {
    if (
      typeof fileManagerAPI.config[namespaceObjectName].manifest[
        entityObjectName
      ] == 'undefined'
    ) {
      return false
    }

    return true
  },

  /**
   * Check if query has filter
   * @param {Object} objQuery
   * @returns
   */
  isQueryProcessableFromCDN(objQuery) {
    const namespace = 'Velocitant'
    var result = true

    if (typeof fileManagerAPI.config[namespace] == 'undefined') {
      result = false
    }

    const base = objQuery.Entity || objQuery.entity

    if (result && !fileManagerAPI.isEntityProcessableFromCDN(namespace, base)) {
      result = false
    }

    if (
      result &&
      !(
        objQuery.filter &&
        objQuery.filter[0] &&
        (objQuery.filter[0].name == 'IsEqualTo' ||
          objQuery.filter[0].name == 'IsIn')
      )
    ) {
      result = false
    }

    if (result && objQuery.include) {
      for (var i = 0, iLen = objQuery.include.length; i < iLen; i++) {
        if (objQuery.include[i].relationship.split('_')[0] !== base) {
          result = false
          break
        }
      }
    }

    return result
  },

  /**
   * Return current version of the entity schema
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   * @returns
   */
  getEntitySchemaVersion(namespaceObjectName, entityObjectName) {
    if (
      typeof fileManagerAPI.config[namespaceObjectName].manifest[
        entityObjectName
      ] !== 'undefined'
    ) {
      return fileManagerAPI.config[namespaceObjectName].manifest[
        entityObjectName
      ].schemaVersion
    }

    return false
  },

  /**
   * Get Entity Schema file
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   */
  getEntitySchema(namespaceObjectName, entityObjectName) {
    let cache = fileManagerAPI.cache[namespaceObjectName][entityObjectName]

    if (typeof cache == 'undefined') {
      fileManagerAPI.cache[namespaceObjectName][entityObjectName] = {}
      cache = fileManagerAPI.cache[namespaceObjectName][entityObjectName]
    }

    if (typeof cache.schema == 'undefined') {
      cache.schema = new Promise(function(resolve) {
        let basePath = fileManagerAPI.config[namespaceObjectName].path
        let version = fileManagerAPI.getEntitySchemaVersion(
          namespaceObjectName,
          entityObjectName
        )

        if (version) {
          let schemaURL = `${basePath}/${namespaceObjectName}/${entityObjectName}/Schema/${version}.json`

          networkManagerBridgeAPI
            .doHttpRequest({
              method: 'get',
              url: schemaURL
            })
            .then(function(schemaResponse) {
              let schema = schemaResponse.data[entityObjectName]
              resolve(schema)
            })
            .catch(function() {
              resolve()
            })
        } else {
          resolve()
        }
      })
    }
  },

  /**
   * Get Record Essential ID for Base Entity
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   */
  getEssenatialIDFieldName: async function(
    namespaceObjectName,
    entityObjectName
  ) {
    const schema = await fileManagerAPI.cache[namespaceObjectName][
      entityObjectName
    ].schema

    const fields = schema.fields
    var recordEssentials
    var recordEssentialIDFieldName

    for (var i = 0, iLen = fields.length; i < iLen; i++) {
      if (typeof fields[i].properties.InterfaceMap !== 'undefined') {
        recordEssentials = fields[i].properties.InterfaceMap.RecordEssentials

        if (recordEssentials) {
          for (var j = 0, jLen = recordEssentials.length; j < jLen; j++) {
            if (recordEssentials[j].field == 'ID') {
              recordEssentialIDFieldName = fields[i].objectName
              break
            }
          }
        }
      }
    }

    return recordEssentialIDFieldName
  },

  /**
   * Get Entity Index files version
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   * @returns
   */
  getEntityIndexVersion(namespaceObjectName, entityObjectName) {
    if (
      typeof fileManagerAPI.config[namespaceObjectName].manifest[
        entityObjectName
      ] !== 'undefined'
    ) {
      return fileManagerAPI.config[namespaceObjectName].manifest[
        entityObjectName
      ].indexVersion
    }

    return false
  },

  /**
   * Get field used in filter
   * @param {*} objFilter
   */
  getFilterField(arrFilter) {
    var fieldName = ''

    for (var i = 0, iLen = arrFilter.length; i < iLen; i++) {
      if (typeof arrFilter[i] !== 'undefined') {
        if (arrFilter[i].name === 'GetFieldValue') {
          fieldName = arrFilter[i].arguments[0].value

          if (
            fieldName == '' &&
            arrFilter[i].arguments[1] &&
            arrFilter[i].arguments[1].value == 'UUID'
          ) {
            fieldName = 'uuid'
          }
          break
        } else {
          return fileManagerAPI.getFilterField(arrFilter[i].arguments)
        }
      }
    }

    return fieldName
  },

  getFilterValue(objFilter) {
    var filterValue = ''

    switch (objFilter.name) {
      case 'IsEqualTo':
        filterValue = [objFilter.arguments[1].value]
        break

      case 'IsIn':
        filterValue = objFilter.arguments[1]

        if (filterValue.name && filterValue.name === 'ToList') {
          filterValue = filterValue.arguments[0].value.split(
            filterValue.arguments[1].value
          )
        } else {
          filterValue = [filterValue.value]
        }

        break
    }

    return filterValue
  },

  /**
   * Get Base Entity Index based on query filter (record essential id or uuid)
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   * @param {*} objQuery
   */
  getEntityIndex(namespaceObjectName, entityObjectName, objQuery) {
    let cache = fileManagerAPI.cache[namespaceObjectName][entityObjectName]

    if (typeof cache == 'undefined') {
      fileManagerAPI.cache[namespaceObjectName][entityObjectName] = {}
      cache = fileManagerAPI.cache[namespaceObjectName][entityObjectName]
    }

    if (typeof cache.index == 'undefined') {
      cache.index = {}
    }

    var filterField = ''

    if (typeof objQuery !== 'undefined') {
      filterField = fileManagerAPI.getFilterField([objQuery.filter[0]])
    }

    let indexNames = ['uuid']
    if (filterField) {
      indexNames.push(filterField)
    }

    for (var i = 0, iLen = indexNames.length; i < iLen; i++) {
      filterField = indexNames[i]

      if (typeof cache.index[filterField] == 'undefined') {
        cache.index[filterField] = new Promise(function(resolve) {
          let basePath = fileManagerAPI.config[namespaceObjectName].path
          let version = fileManagerAPI.getEntityIndexVersion(
            namespaceObjectName,
            entityObjectName
          )

          if (version) {
            let indexURL = `${basePath}/${namespaceObjectName}/${entityObjectName}/Index/${version}/${filterField}.json`

            networkManagerBridgeAPI
              .doHttpRequest({
                method: 'get',
                url: indexURL
              })
              .then(function(indexResponse) {
                let index = indexResponse.data
                resolve(index)
              })
              .catch(function() {
                resolve()
              })
          } else {
            resolve()
          }
        })
      }
    }
  },

  /**
   * Get related entity objectName
   * @param {*} namespaceObjectName
   * @param {*} baseEntity
   * @param {*} relationshipObjectName
   * @returns
   */
  getRelatedEntity: async function(
    namespaceObjectName,
    baseEntity,
    relationshipObjectName
  ) {
    const baseSchema = await fileManagerAPI.cache[namespaceObjectName][
      baseEntity
    ].schema
    const fields = baseSchema.fields
    var relationships

    for (var i = 0, iLen = fields.length; i < iLen; i++) {
      if (fields[i].dataType == 'Relationship') {
        relationships = fields[i].properties.Relationships

        for (var j = 0, jLen = relationships.length; j < jLen; j++) {
          if (
            relationships[j].relationshipObjectName == relationshipObjectName
          ) {
            return relationships[j].entityObjectName
          }
        }
      }
    }

    return ''
  },

  /**
   * Prepare queries data(for base and includes)
   * @param {*} arrIncludes
   * @param {*} baseEntity
   * @param {*} baseRelationship
   * @param {*} arrQueries
   */
  prepareQueryParts(arrIncludes, baseEntity, baseRelationship, arrQueries) {
    if (typeof arrIncludes !== 'undefined') {
      for (let i = 0, iLen = arrIncludes.length; i < iLen; i++) {
        arrQueries.push({
          baseEntity: baseEntity,
          baseRelationship: baseRelationship,
          relationship: arrIncludes[i].relationship,
          relatedEntity: '',
          filter: [],
          index: {},
          data: [],
          originalQuery: false
        })

        fileManagerAPI.prepareQueryParts(
          arrIncludes[i].include,
          '',
          arrIncludes[i].relationship,
          arrQueries
        )
      }
    }
  },

  /**
   * Prepare queries schema and index files
   * @param {*} arrQueries
   */
  prepareQueriesSchema: async function(arrQueries) {
    const namespaceObjectName = 'Velocitant'

    var relationship
    var entityObjectName
    var objRelationshipMap = {}
    var schemaVersion
    var indexVersion
    var queryFilter

    for (let i = 0, iLen = arrQueries.length; i < iLen; i++) {
      relationship = arrQueries[i].relationship

      if (
        arrQueries[i].baseRelationship &&
        objRelationshipMap[arrQueries[i].baseRelationship]
      ) {
        arrQueries[i].baseEntity =
          objRelationshipMap[arrQueries[i].baseRelationship]
      }

      entityObjectName = arrQueries[i].baseEntity

      if (entityObjectName) {
        await fileManagerAPI.getEntitySchema(
          namespaceObjectName,
          entityObjectName
        )

        await fileManagerAPI.getEntityIndex(
          namespaceObjectName,
          entityObjectName,
          arrQueries[i]
        )
      }

      if (relationship) {
        arrQueries[i].relatedEntity = await fileManagerAPI.getRelatedEntity(
          namespaceObjectName,
          entityObjectName,
          relationship
        )

        objRelationshipMap[relationship] = arrQueries[i].relatedEntity

        await fileManagerAPI.getEntitySchema(
          namespaceObjectName,
          arrQueries[i].relatedEntity
        )

        await fileManagerAPI.getEntityIndex(
          namespaceObjectName,
          arrQueries[i].relatedEntity
        )
      }
    }
  },

  /**
   * Download record data file
   * @param {*} namespaceObjectName
   * @param {*} entityObjectName
   * @param {*} dataFileName
   * @returns
   */
  getRecordData: async function(
    namespaceObjectName,
    entityObjectName,
    dataFileName
  ) {
    return new Promise(function(resolve) {
      const basePath = fileManagerAPI.config[namespaceObjectName].path
      const dataUrl = `${basePath}/${namespaceObjectName}/${entityObjectName}/Data/${dataFileName}`

      networkManagerBridgeAPI
        .doHttpRequest({
          method: 'get',
          url: dataUrl
        })
        .then(function(response) {
          var data = response.data
          var path

          if (entityObjectName === 'CodeSnippets') {
            path = data.fields['Path']

            if (path) {
              path = fileManagerAPI.getAbsolutePath(namespaceObjectName, path)
              data.fields['Path'] = path
            }
          }
          resolve(data)
        })
        .catch(function(err) {
          resolve()
        })
    })
  },

  /**
   * Create filter for included entities. Generate filter by uuid
   * @param {*} relatedEntity
   * @param {*} baseData
   */
  createFilterForInclude(relatedEntity, baseData) {
    var namespaceObjectName = 'Velocitant'
    var fields
    var relationships
    var objUniqueIncludedRecords = {}
    var filter = []

    for (var i = 0, iLen = baseData.length; i < iLen; i++) {
      fields = baseData[i].fields

      for (var fieldObjectName in fields) {
        if (
          fields[fieldObjectName] &&
          typeof fields[fieldObjectName] == 'object'
        ) {
          relationships = fields[fieldObjectName]

          for (var j = 0, jLen = relationships.length; j < jLen; j++) {
            if (relationships[j].relatedObjectName === relatedEntity) {
              objUniqueIncludedRecords[relationships[j].uuid] = true
            }
          }
        }
      }
    }

    for (var includeUuid in objUniqueIncludedRecords) {
      filter.push({
        name: 'IsEqualTo',
        arguments: [
          {
            name: 'GetFieldValue',
            arguments: [
              {
                value: ''
              },
              {
                value: 'UUID'
              }
            ]
          },
          {
            value: includeUuid
          }
        ]
      })
    }

    return filter
  },

  /**
   * Apply filters and collect data
   * @param {*} arrQueries
   * @param {*} apiURL
   */
  process: async function(arrQueries) {
    var namespaceObjectName = 'Velocitant'
    var entityObjectName
    var filterValue
    var filterField
    var indexData
    var objData = { status: 'ok', data: [] }
    var recordData
    var objDataMap = {}

    for (var i = 0, iLen = arrQueries.length; i < iLen; i++) {
      objData = { status: 'ok', data: [] }
      entityObjectName = arrQueries[i].baseEntity

      if (i > 0) {
        // for includes
        entityObjectName = arrQueries[i].relatedEntity

        if (objDataMap[arrQueries[i].baseEntity]) {
          arrQueries[i].filter = fileManagerAPI.createFilterForInclude(
            entityObjectName,
            objDataMap[arrQueries[i].baseEntity]
          )
        }
      }

      if (arrQueries[i].filter) {
        for (var j = 0, jLen = arrQueries[i].filter.length; j < jLen; j++) {
          filterField = fileManagerAPI.getFilterField([arrQueries[i].filter[j]])

          filterValue = fileManagerAPI.getFilterValue(arrQueries[i].filter[j]) //arrQueries[i].filter[j].arguments[1].value

          indexData = await fileManagerAPI.cache[namespaceObjectName][
            entityObjectName
          ].index[filterField]

          if (indexData) {
            if (typeof objData.data == 'undefined') {
              objData.data = []
            }

            for (var v = 0, vLen = filterValue.length; v < vLen; v++) {
              if (indexData[filterValue[v]]) {
                recordData = await fileManagerAPI.getRecordData(
                  namespaceObjectName,
                  entityObjectName,
                  indexData[filterValue[v]]
                )
              }

              if (typeof recordData !== 'undefined') {
                objData.data.push(recordData)
              } else {
                // file not found on cdn
                arrQueries[i].data = { status: 'err', data: {} }
                return
              }
            }
          } else {
            // index file not found
            arrQueries[i].data = { status: 'err', data: {} }
            return
          }
        }
      }

      arrQueries[i].data = objData
      objDataMap[entityObjectName] = arrQueries[i].data.data
    }
  },

  /**
   * When error occure, get resource from the  API
   * @param {*} objQuery
   * @param {*} url
   * @returns
   */
  fallbackToAPI(objQuery, url, config) {
    return networkManagerBridgeAPI.doHttpRequest({
      method: 'post',
      url: url,
      postBody: objQuery.originalQuery,
      config: config
    })
  },

  /**
   * Download CDN files described with DSQL Query
   * @param {Object} objQuery
   * @param {String} url
   */
  get: async function(objQuery, url, config) {
    const namespaceObjectName = 'Velocitant'

    let baseEntity = objQuery.entity || objQuery.Entity

    let response = {
      manifest: {
        base: {
          objectName: baseEntity
        },
        data: true,
        schema: true,
        includes: []
      },
      schema: {},
      data: {}
    }

    let arrQueries = [
      {
        baseEntity: baseEntity,
        baseRelationship: '',
        relationship: '',
        relatedEntity: '',
        filter: objQuery.filter || [],
        index: {},
        data: [],
        originalQuery: objQuery
      }
    ]

    fileManagerAPI.prepareQueryParts(
      objQuery.include,
      baseEntity,
      '',
      arrQueries
    )

    await fileManagerAPI.prepareQueriesSchema(arrQueries)
    await fileManagerAPI.process(arrQueries)

    var entityObjectName
    for (var i = 0, iLen = arrQueries.length; i < iLen; i++) {
      entityObjectName = arrQueries[i].relatedEntity || arrQueries[i].baseEntity

      response.schema[entityObjectName] = await fileManagerAPI.cache[
        namespaceObjectName
      ][entityObjectName].schema

      if (!arrQueries[i].originalQuery) {
        response.manifest.includes.push({
          includes: [],
          objectName: arrQueries[i].relatedEntity,
          relationshipObjectName: arrQueries[i].relatinoship
        })
      }

      if (
        typeof response.schema[entityObjectName] == 'undefined' &&
        arrQueries[i].originalQuery
      ) {
        // missing schema
        return fileManagerAPI.fallbackToAPI(arrQueries[i], url, config)
      }

      if (typeof response.data[entityObjectName] == 'undefined') {
        response.data[entityObjectName] = []
      }

      if (arrQueries[i].data.status == 'err') {
        // missing record data
        return fileManagerAPI.fallbackToAPI(arrQueries[0], url, config)
      }

      response.data[entityObjectName] = response.data[entityObjectName].concat(
        arrQueries[i].data.data
      )
    }

    return {
      data: response,
      status: 200,
      statusText: ''
    }
  }
}

export default fileManagerAPI
