import platformBridgeFactory from '@researchmetrics/platform-bridge-factory'
import networkManagerWebImplementation from './network-manager-web-implementation.js'

const networkManagerBridgeAPI = {
  doHttpRequest: function() {},
  getToken: function() {}
}

platformBridgeFactory.create(
  networkManagerBridgeAPI,
  'NetworkManagerBridgeAPI',
  networkManagerWebImplementation
)

var nativeDoHttpReques = networkManagerBridgeAPI.doHttpRequest

networkManagerBridgeAPI.doHttpRequest = function(settings) {
  let processedUrl = settings.url
  if (processedUrl.indexOf('http') === -1) {
    //no origin in the URL
    if (processedUrl.indexOf('/') === 0) {
      processedUrl = document.location.origin + processedUrl
      //absolute URL, adding origin
    } else {
      processedUrl = document.location.href + processedUrl
      //relative URL, adding href
    }
  }
  settings.url = processedUrl
  return nativeDoHttpReques(settings).then(function(result) {
    if (
      typeof settings.config === 'undefined' ||
      typeof settings.config.responseType === 'undefined' ||
      settings.config.responseType === 'json'
    ) {
      if (typeof result.data === 'string') {
        try {
          result.data = JSON.parse(result.data)
        } catch (error) {
          //do nothing
        }
      }
    }

    return result
  })
}

export default networkManagerBridgeAPI
