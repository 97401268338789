/**
 * A module for making HTTP requests.
 *
 * It exports the [networkManager]{@link module:network-manager~networkManager}
 * namespace by default.
 *
 * ## Installation
 *
 * ```
 * npm install --save @researchmetrics/network-manager@latest
 * ```
 * ## Usage as an ES6 module
 *
 * * Required polyfills
 *     * core-js/features/promise
 *
 *
 * ```js
 *     import networkManager from '@researchmetrics/network-manager'
 * ```
 *
 * ## Usage of bundles with `<script>` tag for legacy applications
 *
 * * External dependencies
 *     * [Axios](https://www.npmjs.com/package/axios)
 *     * [Axios-Middleware](https://www.npmjs.com/package/axios-middleware)
 *     * [Oidc-Client](https://www.npmjs.com/package/oidc-client) Choose bundle
 *       from /dist folder. They come with embedded polyfills.
 *
 * ```js
 * <script src="axios/dist/axios.min.js"></script>
 * <script src="axios-middleware/dist/axios-middleware.min.js"></script>
 * <script src="oidc-client/dist/oidc-client.min.js"></script>
 * <script src="@researchmetrics/network-manager/dist/network-manager.umd.js"></script>
 * ```
 *
 * ## Examples
 *
 * ```javascript
 * networkManager.get('https://training62.shopmetrics.com').then(function(responseData){
 *  console.log('Get inbox response data: ', responseData)
 * }).catch(function(error){
 *  switch(error.message){
 *    case networkManager.Errors.ERROR_SERVER_UNREACHABLE:
 *      console.log("Unabled to connect to server. ", error)
 *    break;
 *    default:
 *              console.log("Some other error", error)
 *         break;
 *  }
 * })
 *
 * ```
 * @module network-manager
 */
import networkManagerBridgeAPI from './network-manager-bridge-api.js'
import fileManagerAPI from './file-manager-api.js'
import errors from './network-manager-errors.js'

/**
 * Used for making HTTP request using the session of the active profile.
 * @namespace
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 */
const networkManager = {
  /**
   * Contains error names for the [networkManager]{@link module:network-manager~networkManager} domain.
   * @type {module:network-manager.NetworkErrorNames} */
  Errors: errors,
  /**
   * Gets OAuth bearer token for the current profile.
   *
   * >Note: available only in web browser.
   *
   * @return {string} An OAuth bearer token for the current profile.
   */
  getToken() {
    return networkManagerBridgeAPI.getToken()
  },

  /**
   * Performs HTTP request with GET method
   *
   * @param {string} url - The url of the server
   * @param {module:network-manager~networkManager~RequestConfig} config - Extra
   * options for the request. For example you could specify `responseType` or
   * `headers`.
   * @return {Promise} Promise object that:
   * - resolves with
   *   [NetworkResponse]{@link module:network-manager~networkManager~NetworkResponse}
   *   object containing the information the server gave.
   * - rejects with
   *   [NetworkErrorResponse]{@link module:network-manager~networkManager~NetworkErrorResponse}
   *   or other error containg error information that the server gave.
   */
  get(url, config) {
    return networkManagerBridgeAPI.doHttpRequest({
      method: 'get',
      url: url,
      config: config
    })
  },

  setFileManagerConfig(objConfig) {
    return fileManagerAPI.setConfig(objConfig)
  },

  /**
   * Performs HTTP request with POST method
   *
   * @param {string} url - The url of the server
   * @param {object|string} data - The data to be send to the server.
   * @param {module:network-manager~networkManager~RequestConfig} config - Extra
   * options for the request. For example you could specify `responseType` or
   * `headers`.
   * @return {Promise} Promise object that:
   * - resolves with
   *   [NetworkResponse]{@link module:network-manager~networkManager~NetworkResponse}
   *   object containing the information the server gave.
   * - rejects with
   *   [NetworkErrorResponse]{@link module:network-manager~networkManager~NetworkErrorResponse}
   *   or other error containg error information that the server gave.
   */
  post(url, data, config) {
    if (fileManagerAPI.isQueryProcessableFromCDN(data)) {
      return fileManagerAPI.get(data, url, config)
    }

    return networkManagerBridgeAPI.doHttpRequest({
      method: 'post',
      url: url,
      postBody: data,
      config: config
    })
  },

  /**
   * Performs HTTP request with PUT method
   *
   * @param {string} url - The url of the server
   * @param {object|string} data - The data to be send to the server.
   * @param {module:network-manager~networkManager~RequestConfig} config - Extra
   * options for the request. For example you could specify `responseType` or
   * `headers`.
   * @return {Promise} Promise object that:
   * - resolves with
   *   [NetworkResponse]{@link module:network-manager~networkManager~NetworkResponse}
   *   object containing the information the server gave.
   * - rejects with
   *   [NetworkErrorResponse]{@link module:network-manager~networkManager~NetworkErrorResponse}
   *   or other error containg error information that the server gave.
   */
  put(url, data, config) {
    return networkManagerBridgeAPI.doHttpRequest({
      method: 'put',
      url: url,
      postBody: data,
      config: config
    })
  },

  /**
   * Performs HTTP request with PATCH method
   *
   * @param {string} url - The url of the server
   * @param {object|string} data - The data to be send to the server.
   * @param {module:network-manager~networkManager~RequestConfig} config - Extra
   * options for the request. For example you could specify `responseType` or
   * `headers`.
   * @return {Promise} Promise object that:
   * - resolves with
   *   [NetworkResponse]{@link module:network-manager~networkManager~NetworkResponse}
   *   object containing the information the server gave.
   * - rejects with
   *   [NetworkErrorResponse]{@link module:network-manager~networkManager~NetworkErrorResponse}
   *   or other error containg error information that the server gave.
   */
  patch(url, data, config) {
    return networkManagerBridgeAPI.doHttpRequest({
      method: 'patch',
      url: url,
      postBody: data,
      config: config
    })
  }
}

export default networkManager

/**
 * Object specifying additional configurations for making the request.
 *
 * @typedef {Object} module:network-manager~networkManager~RequestConfig
 * @property {string} responseType - Indicates the type of data that the server
 * will respond with. Options are `json` (default) or `text`.
 *
 * Note: this option is only when api is used in browsers.
 * @property {Object} headers - Object with key value pairs specifiyng the
 * headers.
 */

/**
 * Object representing the response returned from the server
 *
 * @typedef {Object} module:network-manager~networkManager~NetworkResponse
 * @property {number} status - The status code returned from the server
 * @property {string} statusText - The status text returned from the server
 * @property {string} data - The requested resource data
 */

/**
 * Object representing an error response returned from the server
 *
 * @typedef {Object} module:network-manager~networkManager~NetworkErrorResponse
 * @property {number} status - The status code returned from the server
 * @property {string} statusText - The status text returned from the server
 * @property {string} data - The requested resource data
 * @property {string} name - The name of the error. For possible error names see
 * the [Errors]{@link module:network-manager~networkManager.Errors} namespace member.
 * @property {string} message - The description of the error.
 */
