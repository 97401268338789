/**
 * Names of errors that the
 * [networkManager]{@link module:network-manager~networkManager} namespace
 * domain can return.
 *
 * @memberof module:network-manager
 * @name NetworkErrorNames
 * @member
 * @readonly
 * @enum
 * @property {string} ERROR_INVALID_PARAMETERS - When you provide bad parameters
 * or don't provide needed parameters.
 * @property {string} ERROR_UNEXPECTED - May occur if for some reason the API is
 * unable to perform the request.
 * @property {string} ERROR_SERVER_UNREACHABLE - The request was performed,
 * however no response was received.
 * @property {string} ERROR_SERVER_ERROR - The request was performed, however
 * the server status is out of the 2xx range.
 * @property {string} ERROR_AUTHENTICATION_ERROR - There request needed
 * authentication was not provided or was invalid for some reason.
 */
export default {
  ERROR_INVALID_PARAMETERS: 'ERROR_INVALID_PARAMETERS',
  ERROR_UNEXPECTED: 'ERROR_UNEXPECTED',
  ERROR_SERVER_UNREACHABLE: 'ERROR_SERVER_UNREACHABLE',
  ERROR_SERVER_ERROR: 'ERROR_SERVER_ERROR',
  ERROR_AUTHENTICATION_ERROR: 'ERROR_AUTHENTICATION_ERROR'
}
