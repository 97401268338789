import oidcClient from 'oidc-client'

export default {
  getToken(refreshCallback, refreshErrorCallback) {
    var origin = null
    if (typeof window === 'undefined') {
      origin = self.location.protocol + '//' + self.location.hostname
    } else {
      origin = window.location.protocol + '//' + window.location.hostname
    }

    var manager = new oidcClient.UserManager({
      authority: origin + '/OAuth/',
      client_id: 'RM.Application',
      redirect_uri: origin + '/next/OAuth/RM.Application.html',
      silent_redirect_uri: origin + '/next/OAuth/RM.Application.html',
      response_type: 'token',
      scope: 'RM.API',
      automaticSilentRenew: true,
      post_logout_redirect_uri:
        origin + '/next/OAuth/RM.Application.PostLogout.html',
      loadUserInfo: true
    })

    // When a user logs in successfully or a token is renewed, the `userLoaded`
    // event is fired. the `addUserLoaded` method allows to register a callback to
    // that event
    manager.events.addUserLoaded(user => {
      refreshCallback(user.access_token)
    })

    // Same mechanism for when the automatic renewal of a token fails
    manager.events.addSilentRenewError(error => {
      var message =
        'RM.OAuth.js: Error catched in token renew (addSilentRenewError). '
      error.message = message + error.message
      refreshErrorCallback(error)
    })

    return manager.signinSilent()
  }
}
